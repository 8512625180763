<ngx-spinner size="medium" bdColor="rgba(0,0,0,0.3)" [color]="setColorToSpinner()" type="ball-fall" [fullScreen]="true">
    <div class="spinner-status" *ngIf="isLoaded">
        <div class="progress-info-wrapper">
            <svg class="logo-progress-bar" [innerHTML]="leftSideLogo"></svg>
            <div class="notification-data" *ngIf="progressNotification.length > 0; else noData">
                <span>{{ progressAction }}</span>
                <span>{{ percentageValue }} <span *ngIf="percentageValue">%</span></span>
            </div>

            <ng-template #noData> Loading... </ng-template>
            <svg class="logo-progress-bar" [innerHTML]="rightSideLogo"></svg>
        </div>
        <div *ngIf="progressNotification.length > 0" style="text-wrap-mode: nowrap">
            <p>{{ progressNotification }}</p>
        </div>
    </div>
</ngx-spinner>
<router-outlet></router-outlet>
